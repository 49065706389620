import React, {useEffect,useState}  from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import FadeUpOnScroll from "../../components/FadeUp"
import SlideUp from "../../components/SlideUpOnScroll"
import SplitType from 'split-type';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const BlogSection = () => {
    const subtractMonthsFromCurrentDate = (months) => {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - months);
      return currentDate.toLocaleString('default', { month: 'short' });
    }
    const result = subtractMonthsFromCurrentDate(2);
    const result2 = subtractMonthsFromCurrentDate(4);
    useEffect(() => {
      
      // console.log()
        gsap.registerPlugin(ScrollTrigger)
      //hover interaction
      // const title1 = document.querySelector(".blog-section .blog-items .item .reveal-blog-title-1");
      const title2 = document.querySelector(".blog-section .blog-items .item .reveal-blog-title-2");
      const title3 = document.querySelector(".blog-section .blog-items .item .reveal-blog-title-3");

      // const image1 = document.querySelector(".blog-section .blog-items .item .blog-image-1 .gatsby-image-wrapper-constrained");
      const image2 = document.querySelector(".blog-section .blog-items .item .blog-image-2 .gatsby-image-wrapper-constrained");
      const image3 = document.querySelector(".blog-section .blog-items .item .blog-image-3 .gatsby-image-wrapper-constrained");



      const blogHeading = document.querySelectorAll(".blog-section .blog-items .item .blog-title a");
      gsap.set(blogHeading, { autoAlpha: 1 });

      const splitBlogsHeading = new SplitType(blogHeading, {
        type: "words,lines",
        wordsClass: "words",
        linesClass: "lines",
      })

      const tlBlogHeading = gsap.timeline({
        scrollTrigger:{
          trigger: ".blog-section",
        }
      });
      tlBlogHeading.from(splitBlogsHeading.words,{
        duration: 1.4,
        yPercent: 100,
        ease: "Power4.easeInOut",
        rotationX: 0.5,
        rotationY: 0.5,
        z: 0.5
      });

      const blogDate = gsap.timeline({
        scrollTrigger: {
          trigger: ".blog-section"
        }
      })
      blogDate.to(".blog-section .blog-items .item .date",1.4,{
        y: 0,
        opacity: 1,
        // delay: .8,
        ease: "Power4.easeInOut"
      })

      const blogTitle = gsap.timeline({
        scrollTrigger: {
          trigger: ".blog-section"
        }
      })
      blogTitle.to(".blog-section .blog-items .item .blog-title a",1.4,{
        y: 0,
        opacity: 1,
        // delay: .8,
        ease: "Power4.easeInOut"
      })


      // title1.addEventListener("mouseover", () => {
      //   gsap.to(image1, {clip: "rect(auto, 272px, auto, auto)", opacity: 1, duration: .3, ease: "easeInOut",})
      // })
      // title1.addEventListener("mouseleave", () => {
      //   gsap.to(image1, {clip: "rect(auto, 0px, auto, auto)", opacity: 0, duration: .3, ease: "easeInOut",})
      // })
      
      title2.addEventListener("mouseover", () => {
        gsap.to(image2, {clip: "rect(auto, 272px, auto, auto)", opacity: 1, duration: .3, ease: "easeInOut",})
      })
      title2.addEventListener("mouseleave", () => {
        gsap.to(image2, {clip: "rect(auto, 0px, auto, auto)", opacity: 0, duration: .3, ease: "easeInOut",})
      })

      title3.addEventListener("mouseover", () => {
        gsap.to(image3, {clip: "rect(auto, 272px, auto, auto)", opacity: 1, duration: .3, ease: "easeInOut",})
      })
      title3.addEventListener("mouseleave", () => {
        gsap.to(image3, {clip: "rect(auto, 0px, auto, auto)", opacity: 0, duration: .3, ease: "easeInOut",})
      })
    },[])
  return (
    <section className="blog-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-20">
                    <div className="blog-items">
                    <div className="item" >
                            {/* <SlideUp duration={400} delay={500}> */}
                                <div className="row main-row">
                                    <div className="col-lg-18">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <p className="date reveal-blog-date">{result} 05</p>
                                            </div>
                                            <div className="col-lg-20">
                                                <p className="blog-title large reveal-blog-title-3"><Link to="/blog/importance-of-illustrations-and-iconography/">Importance of Illustrations and Iconography in Product Design</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="blog-image-3">
                                            <StaticImage
                                                src="../../assets/images/blog/hand.png"
                                                srcSet="../../assets/images/blog/hand.png 2x, ../../assets/images/blog/hand.png 1x"
                                                // width={212}
                                                quality={95}
                                                formats={["auto", "webp", "avif"]}
                                                alt="Importance of Illustrations and Iconography in Product Design"
                                            />
                                        </div>
                                    </div>
                                </div>
                            {/* </SlideUp> */}
                        </div>
                        <div className="item" >
                            {/* <SlideUp duration={400} delay={500}> */}
                                <div className="row main-row">
                                    <div className="col-lg-18">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <p className="date reveal-blog-date">{result2} 15</p>
                                            </div>
                                            <div className="col-lg-20">
                                                <p className="blog-title large reveal-blog-title-2"><Link to="/blog/accessibility-visual-impairment/">Accessibility, Visual Impairment are you familiar with these terms?</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="blog-image-2">
                                            <StaticImage
                                                src="../../assets/images/blog/businessman.png"
                                                srcSet="../../assets/images/blog/businessman.png 2x, ../../assets/images/blog/businessman.png 1x"
                                                // width={212}
                                                quality={95}
                                                formats={["auto", "webp", "avif"]}
                                                alt="How a world-class design agency organizes files and layouts."
                                            />
                                        </div>
                                    </div>
                                </div>
                            {/* </SlideUp> */}
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-2"></div>
            </div>
        </div>
    </section>
  )
}

export default BlogSection